











































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import NotificationMixin from '@/mixins/notificationMixin'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    PasswordConfirmField
  }
})
export default class ContactUs extends Mixins(NotificationMixin, ValidatorConfigMixin) {
  form: Record<string, any> = {
    message: '',
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  done = true

  created () {
    this.validations()
  }

  submit () {
    this.done = false
    axios.post('contact-us', this.form)
      .then(response => {
        this.setNotification('success', response.data.data)
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
